import iziToast from 'izitoast';
import { themes } from '../components/mui/themes';

const defaultProps = {
  position: 'topCenter',
  drag: false,
  zindex: 3001,
};

const useToast = () => ({
  success: (message, options = {}) => iziToast.show({
    ...defaultProps,
    timeout: 3000,
    theme: 'dark',
    class: 'loading-delete-selection',
    color: themes.geoimpact.palette.primary.main,
    message,
    ...options,
  }),
  warning: (message, options = {}) => iziToast.warning({
    ...defaultProps,
    color: themes.geoimpact.palette.warning.main,
    message,
    ...options,
  }),
  error: (message, options = {}) => iziToast.error({
    ...defaultProps,
    color: themes.geoimpact.palette.error.main,
    message,
    ...options,
  }),
});

export default useToast;
