import {
  Stack, Typography, TextField, LinearProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import * as loglevel from 'loglevel';
import { createUserSettings, getUserSettings, updateUserSettings } from '../../../contexts/dashboard/API';
import SEPContext from '../../../contexts/sep-context/SEPContext';
import env from '../../../env/env';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

const DEFAULT_SETTINGS = {
  exportLimit: 15000,
  batchSize: 50,
};

function UserMSExportSettings() {
  const { t } = useTranslation('account');
  const { user: { jwt } } = useContext(SEPContext).SEPContext;

  const [settings, setSettings] = useState(DEFAULT_SETTINGS);
  const [isLoading, setIsLoading] = useState(true);
  const [settingsId, setSettingsId] = useState(null);

  // Fetch settings from backend
  const fetchSettings = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getUserSettings(jwt);
      const userSettings = response.data.find((s) => s.settingsType === 'ms_export_settings');

      if (userSettings) {
        setSettings(JSON.parse(userSettings.settings));
        setSettingsId(userSettings.id);
      } else {
        // No settings exist, save the default settings
        const newSettings = await createUserSettings(jwt, 'ms_export_settings', JSON.stringify(DEFAULT_SETTINGS));
        setSettingsId(newSettings.data.id);
      }
    } catch (error) {
      log.error('Error fetching settings:', error);
    } finally {
      setIsLoading(false);
    }
  }, [jwt]);

  // Save settings to backend
  const saveSettings = useCallback(async (newSettings) => {
    try {
      if (settingsId) {
        await updateUserSettings(jwt, settingsId, 'ms_export_settings', JSON.stringify(newSettings));
      } else {
        const response = await createUserSettings(jwt, 'ms_export_settings', JSON.stringify(newSettings));
        setSettingsId(response.data.id);
      }
      setSettings(newSettings);
    } catch (error) {
      log.error('Error saving settings:', error);
    }
  }, [jwt, settingsId]);

  // Handle input change
  const handleInputChange = async (key, value) => {
    const newSettings = { ...settings, [key]: value };
    setSettings(newSettings);
    await saveSettings(newSettings);
  };

  useEffect(() => {
    (async () => {
      await fetchSettings();
    })();
  }, [fetchSettings]);

  if (isLoading) return <LinearProgress />;

  return (
    <Stack spacing={1}>
      <Typography component="h3" variant="b">
        {t('label-ms-export-settings')}
      </Typography>
      <Typography>
        {t('panel-ms-export-description')}
      </Typography>
      <Stack spacing={2} sx={{ mt: '20px !important' }}>
        <TextField
          label={t('label-ms-export-settings-export-limit')}
          variant="outlined"
          fullWidth
          value={settings.exportLimit}
          type="number"
          inputProps={{ min: 1 }}
          onChange={(e) => handleInputChange('exportLimit', Number(e.target.value))}
        />
        <TextField
          label={t('label-ms-export-settings-batch-size')}
          helperText={t('helper-ms-export-settings-batch-size-helper')}
          variant="outlined"
          fullWidth
          value={settings.batchSize}
          type="number"
          inputProps={{ min: 1, max: 500 }}
          onChange={(e) => handleInputChange('batchSize', Number(e.target.value))}
        />
      </Stack>
    </Stack>
  );
}

export default UserMSExportSettings;
